import { PaymentControllerService } from '@/__generated__/CommonApi';
import btsImage from '@/assets/img/support/en_donate_bts@2x.png';
import imgSupportStep01 from '@/assets/img/support/support_step_01@2x.png';
import imgSupportStep02 from '@/assets/img/support/support_step_02@2x.png';
import imgSupportStep03 from '@/assets/img/support/support_step_03@2x.png';
import Agreement from '@/components/Agreement';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import HR from '@/components/HR';
import InputGroupCard, {
  InputGroupCardDash,
  InputGroupCardSlash,
} from '@/components/Input/InputGroupCard';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import { NotiBox } from '@/components/Text/TextNoti';
import { H2, H4 } from '@/components/Titles';
import { EnglishPrivacyPolicyContent } from '@/data/agreements';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isMobileDevice } from '@/helpers/BrowserHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { PAYTYPE_ONCE_CARD } from '@/helpers/PaymentHelper';
import {
  ENUM_SUPPORTER_TYPE,
  PAGE_DONATE_ENDVIOLENCE_EN,
  SOLICIT_CODE_ENDVIOLENCE,
  SUPPORT_CATEGORY_ONCE_WORLD,
  SUPPORT_TERM_ONCE,
  useFindSupportCategory,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { DonateFormItem } from '@/page-blocks/donate/DonateCommon';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

const Container = styled.div`
  max-width: 544px;
  margin: 0 auto 24px;
  ${breakpoint(640)} {
    width: 100%;
    padding: 0 20px;
  }
`;
const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  color: #b7b8ba;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
`;
const StepBody = styled.div`
  // padding-bottom: 56px;
  display: none;
`;
const StepImage = styled.img<{ active?: boolean }>`
  width: 100%;
  height: 48px;
  box-sizing: content-box;
  object-fit: contain;
  padding: 24px 0;
  border-bottom: 1px solid #b7b8ba;
  display: none;
  ${({ active }) => {
    if (active) {
      return `
        display: block;
      `;
    }
    return ``;
  }}
`;
const ToggleBoxActive = css`
  ${H2} {
    color: #2d2926;
  }
  form {
    display: block;
  }
  ${StepBody} {
    display: block;
  }
`;
const ToggleBox = styled.div<{ active: boolean }>`
  ${H2} {
    color: #b7b8ba;
  }
  form {
    display: none;
  }
  border-bottom: 1px solid #b7b8ba;
  ${StepBody} {
    display: none;
  }
  ${NotiBox} {
    margin-bottom: 24px;
  }
  ${({ active }) => active && ToggleBoxActive};
`;
const FormResult = styled.div`
  color: #1cabe2;
  width: 100%;
  margin-top: 5px;
`;
const FormModify = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  line-height: 1;
  text-decoration: underline;
  color: #828385;
  font-size: 16px;
`;

const FormContainer = styled.div`
  ${InputRadioGroup} {
    ${breakpoint(`tablet`)} {
      ${InputText} {
        height: 44px;
      }
    }
  }
`;

const BtsBox = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  ${H4} {
    color: #1cabe2;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.8px;
  }
`;

const FormTotal = styled.div`
  position: relative;
  padding: 32px 0;
  margin: 56px 0 80px 0;
  border-top: 1px solid #b7b8ba;
  border-bottom: 1px solid #b7b8ba;
  ${H4} {
    position: absolute;
    left: 0;
    bottom: 16px;
    margin: 0;
  }
`;

const FormTotalPrice = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #1cabe2;
  text-align: right;
`;

interface EnSupportStep1Data {
  supportAmount: string;
  supportAmountInput: string;
}

interface EnDonateStep1Props {
  onSubmit: (data: EnSupportStep1Data) => void;
  queryParams: any;
}

const EnDonateStep1: FC<EnDonateStep1Props> = ({ onSubmit, queryParams }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    control,
  } = useForm<EnSupportStep1Data>({
    defaultValues: {
      supportAmount: queryParams.supportAmount || `30000`,
      supportAmountInput: queryParams.supportAmountInput || ``,
    },
  });

  const supportAmount = watch(`supportAmount`, `0`);
  const supportAmountInput = watch(`supportAmountInput`);
  const finalAmount = useMemo(
    () => Number(supportAmount || supportAmountInput),
    [supportAmount, supportAmountInput],
  );

  const onSubmitForm: SubmitHandler<EnSupportStep1Data> = useCallback(
    (formData) => {
      if (!finalAmount) {
        setError(`supportAmountInput`, {
          type: `required`,
          message: `Donate amount is required`,
          shouldFocus: true,
        });

        return;
      }

      onSubmit(formData);
    },
    [finalAmount, onSubmit, setError],
  );

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <HR color="#1cabe2" />
        <DonateFormItem
          css={`
            margin-bottom: 56px;
          `}
        >
          <H4>Donation Amount (won)</H4>
          <InputRadioGroup full>
            <InputRadio
              ref={register}
              name="supportAmount"
              tab
              value="30000"
              label="30,000"
              onFocus={() => setValue(`supportAmountInput`, ``)}
            />
            <InputRadio
              ref={register}
              name="supportAmount"
              tab
              value="50000"
              label="50,000"
              onFocus={() => setValue(`supportAmountInput`, ``)}
            />
            <InputRadio
              ref={register}
              name="supportAmount"
              tab
              value="100000"
              label="100,000"
              onFocus={() => setValue(`supportAmountInput`, ``)}
            />
            <InputRadio
              ref={register}
              name="supportAmount"
              tab
              value="200000"
              label="200,000"
              onFocus={() => setValue(`supportAmountInput`, ``)}
            />
          </InputRadioGroup>
          <Controller
            control={control}
            name="supportAmountInput"
            as={
              <InputNumber
                allowNegative={false}
                onFocus={() => setValue(`supportAmount`, ``)}
                placeholder="Please select or enter your Donation amount."
                css={`
                  margin-top: 8px;
                `}
              />
            }
          />
          {errors.supportAmountInput && (
            <ErrorMessage>{errors.supportAmountInput.message}</ErrorMessage>
          )}
          <img
            src={btsImage}
            alt="BTS ENDviolence"
            width="100%"
            css={`
              margin-top: 24px;
            `}
          />
          <BtsBox>
            <H4>#ENDviolence</H4>
            <p>
              UNICEF works in 190 countries and territories to save
              children&apos;s lives. To defend their rights. To help them fulfil
              their potential. <br />
              And we never give up. UNICEF, for every child.
            </p>
          </BtsBox>
          <FormTotal>
            <H4>Donation Amount</H4>
            <FormTotalPrice>
              {Number(finalAmount).toLocaleString()}won
            </FormTotalPrice>
          </FormTotal>
          <Button type="submit" full>
            next
          </Button>
        </DonateFormItem>
      </form>
    </FormContainer>
  );
};

interface EnSupportStep2Data {
  memberName: string;
  email: string;
}

interface EnDonateStep2Props {
  onSubmit: (data: EnSupportStep2Data) => void;
}

const EnDonateStep2: FC<EnDonateStep2Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EnSupportStep2Data>({
    defaultValues: {
      memberName: ``,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HR color="#1cabe2" />
      <DonateFormItem>
        <H4>Your Information</H4>
        <FormGroup>
          <Label required>Full Name</Label>
          <InputText
            ref={register({
              required: {
                value: true,
                message: `Required input`,
              },
            })}
            name="memberName"
            placeholder="Enter your full name"
          />
          {errors.memberName && (
            <ErrorMessage>{errors.memberName.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label required>Email Address</Label>
          <InputText
            ref={register({
              required: {
                value: true,
                message: `Required input`,
              },
            })}
            name="email"
            placeholder="Enter your email address"
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </FormGroup>
        <Button
          full
          type="submit"
          css={`
            margin-top: 96px;
            margin-bottom: 56px;
          `}
        >
          next
        </Button>
      </DonateFormItem>
    </form>
  );
};

interface EnSupportStep3Data {
  // 신용카드 정보
  cardNumber1: string;
  cardNumber2: string;
  cardNumber3: string;
  cardNumber4: string;
  cardExpireM: string;
  cardExpireY: string;
  // 이용약관
  policyAgree: boolean;
  // 개발환경용 생년월일 필드
  depositorBirthday: string;
}
interface EnDonateStep3Props {
  onSubmit: (data: EnSupportStep3Data) => void;
}
const EnDonateStep3: FC<EnDonateStep3Props> = ({ onSubmit }) => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
  } = useForm<EnSupportStep3Data>({
    defaultValues: {
      signBinary: ``,
      cardNumber1: ``,
      cardNumber2: ``,
      cardNumber3: ``,
      cardNumber4: ``,
      cardExpireM: ``,
      cardExpireY: ``,
    },
  });

  const cardNumberError =
    errors.cardNumber1 ||
    errors.cardNumber2 ||
    errors.cardNumber3 ||
    errors.cardNumber4;

  const cardExpireError = errors.cardExpireM || errors.cardExpireY;

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HR color="#1cabe2" />
        <DonateFormItem>
          <H4>Credit Card Number</H4>
          <InputGroupCard>
            <Controller
              control={control}
              name="cardNumber1"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="####"
                  placeholder="1234"
                  onKeyUp={(e) =>
                    e.currentTarget.value.length === 4 &&
                    e.currentTarget.nextSibling?.nextSibling?.focus()
                  }
                  autocomplete="cc-number"
                />
              }
            />
            <InputGroupCardDash />
            <Controller
              control={control}
              name="cardNumber2"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="####"
                  placeholder="0000"
                  onKeyUp={(e) =>
                    e.currentTarget.value.length === 4 &&
                    e.currentTarget.nextSibling?.nextSibling?.focus()
                  }
                  autocomplete="cc-number"
                />
              }
            />
            <InputGroupCardDash />
            <Controller
              control={control}
              name="cardNumber3"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="####"
                  placeholder="0000"
                  onKeyUp={(e) =>
                    e.currentTarget.value.length === 4 &&
                    e.currentTarget.nextSibling?.nextSibling?.focus()
                  }
                  autocomplete="cc-number"
                />
              }
            />
            <InputGroupCardDash />
            <Controller
              control={control}
              name="cardNumber4"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="####"
                  placeholder="0000"
                  autocomplete="cc-number"
                />
              }
            />
          </InputGroupCard>
          {cardNumberError && (
            <ErrorMessage>{cardNumberError.message}</ErrorMessage>
          )}
        </DonateFormItem>
        <DonateFormItem>
          <H4>Expiration Date</H4>
          <InputGroupCard>
            <Controller
              control={control}
              name="cardExpireM"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="##"
                  placeholder="MM"
                  onKeyUp={(e) =>
                    e.currentTarget.value.length === 2 &&
                    e.currentTarget.nextSibling?.nextSibling?.focus()
                  }
                  autocomplete="cc-exp-month"
                />
              }
            />
            <InputGroupCardSlash />
            <Controller
              control={control}
              name="cardExpireY"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
              }}
              as={
                <InputNumber
                  format="##"
                  placeholder="YY"
                  autocomplete="cc-exp-year"
                />
              }
            />
          </InputGroupCard>
          {cardExpireError && (
            <ErrorMessage>{cardExpireError.message}</ErrorMessage>
          )}
        </DonateFormItem>
        {/* 운영환경이 아니면 항상 출력되어야 함. (테스트용 이니시스 mid의 필수값이기 때문) */}
        {process.env.GATSBY_ACTIVE_ENV !== `production` && (
          <DonateFormItem>
            <H4>Cardholder&apos;s Date of Birth (for Test)</H4>
            <Controller
              control={control}
              name="depositorBirthday"
              rules={{
                required: {
                  value: true,
                  message: `Required input`,
                },
                validate: (value) =>
                  dayjs(value, `YYYYMMDD`, true).isValid() || `Invaid format`,
              }}
              as={
                <InputNumber
                  format="########"
                  placeholder="Enter 8 digit date of birth"
                  css={`
                    margin-top: 12px;
                  `}
                />
              }
            />
          </DonateFormItem>
        )}
        <StepHead>
          <H2>The collection and use of personal information</H2>
        </StepHead>
        <HR
          color="#b7b8ba"
          css={`
            margin-bottom: 20px;
          `}
        />
        <Agreement
          name="policyAgree"
          ref={register({
            required: {
              value: true,
              message: `Please agree to the terms and conditions`,
            },
          })}
          policies={[
            {
              required: true,
              label: `Privacy Policy`,
              content: EnglishPrivacyPolicyContent,
            },
          ]}
          labels={{
            agreeAll: ``,
            required: ``,
            optional: ``,
            more: `more`,
          }}
        />
        {errors.policyAgree && (
          <ErrorMessage>{errors.policyAgree.message}</ErrorMessage>
        )}
        <Button
          type="submit"
          color="yellow"
          full
          css={`
            margin: 92px 0;
          `}
          disabled={isSubmitting}
        >
          Donate Now
        </Button>
      </form>
    </FormContainer>
  );
};

// 고정데이터 (영문 endviolence 전용)
const FixedData = {
  supportTerm: SUPPORT_TERM_ONCE,
  supportCategory: SUPPORT_CATEGORY_ONCE_WORLD,
  supporterType: ENUM_SUPPORTER_TYPE.person,
  memberTypeCode: `IN`,
  solicitCode: SOLICIT_CODE_ENDVIOLENCE,
  paymentType: PAYTYPE_ONCE_CARD,
};

const EnDonatePage: FC<PageProps> = ({ location }) => {
  const popupStore = usePopupStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Data, setStep1Data] = useState<EnSupportStep1Data>();
  const [step2Data, setStep2Data] = useState<EnSupportStep2Data>();
  const totalData = useMemo(() => ({ ...step1Data, ...step2Data }), [
    step1Data,
    step2Data,
  ]);
  const queryParams = queryString.parse(location.search);
  // 카테고리 object 가져오기
  const finalSupportCategory = useFindSupportCategory(
    SUPPORT_CATEGORY_ONCE_WORLD,
  );

  // 총 후원금액
  const finalAmount = useMemo(() => {
    const { supportAmount, supportAmountInput } = totalData;
    return Number(supportAmount || supportAmountInput || 0);
  }, [totalData]);

  const onStep1Submit: SubmitHandler<EnSupportStep1Data> = useCallback(
    (data) => {
      // if (finalSupportCategory) {
      //   GtmHelper.checkoutStep1({
      //     supportAmount: Number(
      //       data.supportAmount || data.supportAmountInput || 0,
      //     ),
      //     supportCategory: finalSupportCategory,
      //   });
      // }
      setStep1Data(data);
      setCurrentStep(2);
    },
    [],
  );

  const onStep2Submit: SubmitHandler<EnSupportStep2Data> = useCallback(
    (data) => {
      // GtmHelper.checkoutStep2({
      //   supporterType: FixedData.supporterType,
      //   memberTypeCode: FixedData.memberTypeCode,
      // });
      setStep2Data(data);
      setCurrentStep(3);
    },
    [],
  );

  const onSubmit: SubmitHandler<EnSupportStep3Data> = useCallback(
    async (step3Data) => {
      // 고정데이터와 폼데이터 병합
      const finalData = { ...totalData, ...step3Data, ...FixedData };

      // // GTM checkout 이벤트 발생
      // GtmHelper.checkoutStep3({
      //   supporterType: finalData.supporterType,
      //   memberTypeCode: finalData.memberTypeCode,
      //   paymentType: finalData.paymentType,
      // });

      // 영문결제 api (이니시스 빌링)
      try {
        const {
          resultCode,
          resultMessage,
          orderToken,
          orderNumber,
        } = await PaymentControllerService.paymentInfoSetEnUsingPost({
          cardNumber:
            finalData.cardNumber1 +
            finalData.cardNumber2 +
            finalData.cardNumber3 +
            finalData.cardNumber4,
          cardExpire: finalData.cardExpireY + finalData.cardExpireM,
          email: finalData.email,
          deviceType: isMobileDevice() ? `MOBILE` : `PC`,
          memberName: finalData.memberName,
          totalAmount: finalAmount,
          // 기타 정보
          moduleCode: `EMPTY`,
          solicitCode: finalData.solicitCode,
          // trackcode 저장
          trackcode: SessionStorage.getItem(`trackcode`) || undefined,
          trk_id: SessionStorage.getItem(`trk_id`) || undefined,
          // 개발환경용 변수
          depositorBirthday: finalData.depositorBirthday,
        });

        // 주문번호 생성에 성공하면
        if (resultCode === `success`) {
          // 주문토큰 저장
          SessionStorage.setItem(`orderToken`, orderToken);
          // trackcode, trk_id 비우기
          SessionStorage.removeItem(`trackcode`);
          SessionStorage.removeItem(`trk_id`);
          // 바로 이동
          navigate(
            `${PAGE_DONATE_ENDVIOLENCE_EN}/complete?orderNumber=${orderNumber}`,
          );
        }
        // 주문번호 생성에 실패하면
        else if (resultMessage) {
          popupStore.show(resultMessage);
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.resultMessage);
          console.error(e);
        }
      }
    },
    [finalAmount, popupStore, totalData],
  );

  return (
    <LayoutWithTitle location={location} title="Donate" hideGnb>
      <Container>
        <StepImage
          src={imgSupportStep01}
          active={currentStep === 1}
          alt="Donate Step1"
        />
        <StepImage
          src={imgSupportStep02}
          active={currentStep === 2}
          alt="Donate Step2"
        />
        <StepImage
          src={imgSupportStep03}
          active={currentStep === 3}
          alt="Donate Step3"
        />
        <ToggleBox active={currentStep === 1}>
          <StepHead>
            <H2>1. Donation Amount</H2>
            {currentStep !== 1 && (
              <FormModify type="button" onClick={() => setCurrentStep(1)}>
                edit{` `}
              </FormModify>
            )}
            <FormResult>
              {finalAmount ? `${finalAmount.toLocaleString()} won` : null}
            </FormResult>
          </StepHead>
          <StepBody>
            <EnDonateStep1 queryParams={queryParams} onSubmit={onStep1Submit} />
          </StepBody>
        </ToggleBox>
        <ToggleBox active={currentStep === 2}>
          <StepHead>
            <H2>2. Your Information</H2>
            <FormModify type="button" onClick={() => setCurrentStep(2)}>
              edit{` `}
            </FormModify>
            <FormResult>{step2Data?.memberName}</FormResult>
          </StepHead>
          <StepBody>
            <EnDonateStep2 onSubmit={onStep2Submit} />
          </StepBody>
        </ToggleBox>
        <ToggleBox active={currentStep === 3}>
          <StepHead>
            <H2>3. Payment Information</H2>
          </StepHead>
          <StepBody>
            <EnDonateStep3 onSubmit={onSubmit} />
          </StepBody>
        </ToggleBox>
      </Container>
    </LayoutWithTitle>
  );
};

export default EnDonatePage;
