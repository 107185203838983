import React, { FC } from 'react';
import styled from 'styled-components';

const Dash = styled.div`
  display: inline-block;
  width: 14px;
  vertical-align: middle;
  line-height: 56px;
  input:read-only + & {
    background: #f8f9fa;
  }
`;
export const InputGroupCardDash: FC = () => <Dash>-</Dash>;
export const InputGroupCardSlash: FC = () => <Dash>/</Dash>;
const InputGroupCardWrap = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #d9d9d9;
  input,
  input:read-only {
    border: 1px solid transparent;
  }
  input[readonly] {
    border: 1px solid transparent;
  }
`;
const InputGroupCardContainer = styled.div`
  position: relative;
`;

const InputGroupCard: FC = ({ children }) => (
  <InputGroupCardContainer>
    <InputGroupCardWrap>{children}</InputGroupCardWrap>
  </InputGroupCardContainer>
);
export default InputGroupCard;
